
import { defineComponent } from "@vue/runtime-core";
import { languagesAll, countries } from "countries-list";
import { getInitials } from "@/utils/ui";
import { User } from "@toppick/common/build/interfaces";
import HeaderSection from "./HeaderSection.vue";
import Loader from "@/components/ui/Loader.vue";
import { PROFESSIONS } from "../../constants/registration";
import "./styles.css";
import { getUserDetails } from "@toppick/common/build/api";
import { getAuthToken } from "@/utils/auth";

export default defineComponent({
  components: { HeaderSection, Loader },
  data() {
    return {
      isModified: false,
      isLoading: true,
      error: "" as string,
      success: "" as string,
      user: null as null | User,

      file: null,
    };
  },

  methods: {
    async loadData() {
      this.user = await getUserDetails(await getAuthToken(), {
        username: this.$route.params.username as string,
      });
    },

    getLanguageLabel(value: string): string {
      return languagesAll[value].name;
    },
    getCountryLabel(value: string): string {
      return countries[value].name;
    },
  },

  computed: {
    countries() {
      return Object.keys(countries).map((key) => ({
        value: key,
        label: countries[key].name,
      }));
    },

    initials(): string {
      return this.user ? getInitials(this.user.username) : "";
    },

    professions() {
      return PROFESSIONS.map((val) => ({ value: val, label: val }));
    },

    languages() {
      return Object.keys(languagesAll).map((key) => ({
        value: key,
        label: languagesAll[key].name,
      }));
    },
  },
  async mounted() {
    try {
      await this.loadData();
    } catch (error) {
      console.log(error);
    }
    this.isLoading = false;
  },
});
