
import { defineComponent } from "@vue/runtime-core";
import "./styles.css";

export default defineComponent({
  props: {
    title: String,
  },
  data() {
    return {
      RightArrow: require("@/assets/images/right-arrow-black.svg"),
    };
  },
});
