import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "space cmn_hero_banner inner_cmn_hero text-center" }
const _hoisted_2 = { class: "container" }
const _hoisted_3 = { class: "Breadcrums" }
const _hoisted_4 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h1", null, _toDisplayString(_ctx.title), 1),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_router_link, {
          class: "mx-2",
          to: { name: 'Home' }
        }, {
          default: _withCtx(() => [
            _createTextVNode("Home")
          ]),
          _: 1
        }),
        _createElementVNode("img", {
          src: _ctx.RightArrow,
          alt: "",
          class: "mx-2"
        }, null, 8, _hoisted_4),
        _createVNode(_component_router_link, {
          class: "active",
          to: { name: 'ViewProfile', username: _ctx.title }
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.title), 1)
          ]),
          _: 1
        }, 8, ["to"])
      ])
    ])
  ]))
}